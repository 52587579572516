const $ = require('./js/plugins/jquery.min');
global.$ = global.jQuery = $;
global.Swiper = require('./js/plugins/swiper/swiper.min.js');
require('./js/src/modules');
global.io = require('./js/plugins/socket.io.min.js');
require('./js/plugins/jquery.validate.min');
require('./js/src/captcha');
require('./js/src/validation');
require('./js/src/burger');
require('./js/src/select');
require('./js/src/sliders');
require('./js/src/accordion');
require('./js/src/main');
require('./js/src/preloader');
require('./js/src/ajax');
require('./js/src/load_maps');
require('./js/src/search');
require('./js/src/socket');
require('./js/src/popups');
require('./js/src/notifications');
require('./js/src/footer_details');
require('./js/src/letter_counter');
require('./js/src/ajax_popups');
require('./js/src/lazy');
require('./js/src/feedback_request');

jQuery(function () {
  $('.list-question').accordion();
  global.modules.init();
})

require('./js/plugins/swiper/swiper.min.css');
require('./css/basic.css');
require('./css/footer.css');
require('./css/header.css');
require('./css/style-bundle.css');
require('./css/how-it.css');
require('./css/about-us.css');
require('./css/main-page.css');
require('./css/networks.css');
require('./css/pricing.css');
require('./css/blog.css');
require('./css/blog-details.css');
require('./css/listing.css');
require('./css/normalize.css')
require('./js/plugins/datepicker/datepicker.min.css');