global.modules.set({
  is_booted: false,
  async init() {
    if(this.is_booted) {
      return;
    }
    this.is_booted = true
    $('.header__burger').on('click', function () {
      $(this).toggleClass('active')
      $('.nav-wrap').toggleClass('active');
      $('.main-header__body').toggleClass('active');
      $('body').toggleClass('lock');
    })
    $(window).on('resize', function (e) {
      if ($(this).width() > 1000) {
        $('.main-header__body').removeClass('active');
      } else if ($('.header__burger').hasClass('active')) {
        $('.main-header__body').addClass('active');
        $('body').addClass('lock');
      }
    })
  }
})