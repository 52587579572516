global.modules.set({
  async init(context) {
    $('form.ajax_popup', context).on('submit', function (e) {
      e.preventDefault();
      var thisForm = $(this);
      const formData = new FormData(thisForm[0]);
      $.ajax({
        url: thisForm.attr('action'),
        data: formData,
        method: thisForm.attr('method'),
        complete: null,
        beforeSend: null,
        success: function (data, statusText, jqXhr) {
          if(jqXhr.responseJSON) {
            global.successHandler.call(this, data)
          } else {
            var elem = $(data);
            $('.main').append(elem);
            if(elem.attr('script-src')) {
              var script = document.createElement('script');
              script.src = elem.attr('script-src');
              script.async = true;
              script.onload = function() {
                global.modules.init(elem);
              };
              elem[0].appendChild(script);
            } else {
              global.modules.init(elem);
            }
            $('body').addClass('lock');
            elem.on('hide', function() {
              elem.remove();
            })
          }
        }
      });
    })
  }
})