global.modules.set({
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;
    const howWorksSlider = document.querySelector('.how-works-slider');

    if (howWorksSlider) {
      new Swiper(howWorksSlider, {
        slidesPerView: 1,
        spaceBetween: 5,
        pagination: {
          el: '.how-works-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.how-works .btn-next',
          prevEl: '.how-works .btn-prev',
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          700: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 4,
          },

        }
      })
    }

    let reviewsSlider = document.querySelector('.reviews .slider-reviews')
    if (reviewsSlider) {
      new Swiper(reviewsSlider, {
        slidesPerView: 1,
        loop: true,
        watchOverflow: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          450: {
            slidesPerView: 2,
          },
          700: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 4,
          },
        },
      })
    }

    let loggingSlider = document.querySelector('.logging__slider');
    if (loggingSlider) {
      new Swiper(loggingSlider, {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.logging-pagination',
          clickable: true,
        },
      })
    }

    // slider listing details
    let listingImages = document.querySelector('.listing-main-slider');
    let listingMiniImages = document.querySelector('.listing-nav-slider');
    let listingSlidesNav = null;
    let listingSlides = null;
    let activeSlide = null;

    if (listingImages) {
      listingSlidesNav = new Swiper(listingMiniImages, {
        spaceBetween: 5,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
          576: {
            slidesPerView: 5
          },
          992: {
            slidesPerView: 7,
          }
        }
      });

      listingSlides = new Swiper(listingImages, {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".listing-images__next",
          prevEl: ".listing-images__prev",
        },
        thumbs: {
          swiper: listingSlidesNav,
        },
        on: {
          resize: function () {
            listingSlides.update()
          },

          activeIndexChange: function () {
            activeSlide = listingSlides.activeIndex;
          }
        },
      });
    }

    // listing gallery
    let btnGalleryOpen = $('.listing-images-screen');
    let galleryDetails = document.querySelector('.gallery-main-slider');
    let galleryNavDetails = document.querySelector('.gallery-nav-slider');
    gallerySlidesNav = new Swiper(galleryNavDetails, {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      loop: false,
      centeredSlides: true,
      breakpoints: {
        576: {
          slidesPerView: 5
        },
        992: {
          slidesPerView: 8,
        }
      },
      on: {
        resize: function () {
          gallerySlides.update()
        },
      },
    });

    gallerySlides = new Swiper(galleryDetails, {
      slidesPerView: 1,
      spaceBetween: 50,
      loop: true,
      navigation: {
        nextEl: ".gallery-images__next",
        prevEl: ".gallery-images__prev",
      },
      thumbs: {
        swiper: gallerySlidesNav,
      },
      on: {
        resize: function () {
          gallerySlides.update()
        },
      },
    });
    btnGalleryOpen.each(function () {
      this.addEventListener('click', () => {
        gallerySlides.update()
        gallerySlidesNav.update()
        gallerySlides.slideTo(activeSlide + 1)
      })
    })
    // if (btnGalleryOpen) {
    //   btnGalleryOpen.addEventListener('click', () => {
    //     gallerySlides.update()
    //     gallerySlidesNav.update()
    //     gallerySlides.slideTo(activeSlide + 1)
    //   })
    // }

    // show map
    let mapBlock = document.querySelector('.listing-details .details-map');
    let showOnMap = document.querySelector('.listing-details .show-map');
    let listingSlider = document.querySelector('.listing-slider');
    let returnSlider = document.querySelector('.return-slider');
    if (mapBlock) {
      showOnMap.addEventListener('click', () => {
        listingSlider.hidden = true;
        mapBlock.hidden = false;
        mapBlock.nextElementSibling.classList.add('d-block')
        showOnMap.classList.remove('d-block');
        showOnMap.hidden = true;
        const map = mapBlock.getBoundingClientRect();
        // window.scrollTo(map.top, 0);
        window.scrollTo({
          top: map.y + window.scrollY - $('.main-header').height(),
          behavior: 'smooth'
        });
      })
      returnSlider.addEventListener('click', () => {
        mapBlock.hidden = true;
        listingSlider.hidden = false;
        mapBlock.nextElementSibling.classList.remove('d-block')
        showOnMap.classList.add('d-block');
        showOnMap.hidden = false;

        if (!returnSlider.classList.contains('wants-btn')) {
          if (listingSlides) {
            listingSlides.update();
          }
          if (listingSlidesNav) {
            listingSlidesNav.update();
          }
        }
      })
    }

    let promotionSlider = document.querySelector('.promotion-slider');
    if (promotionSlider) {
      new Swiper(document.querySelector('.promotion-slider'), {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.btn-next',
          prevEl: '.btn-prev',
        },
        breakpoints: {
          600: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
            watchOverflow: true,
          }
        }
      })
    }

    const similarListings = document.querySelector('.similar-listings__slider .swiper-container');

    if (similarListings) {
      new Swiper(similarListings, {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.similar-listings__slider .btn-next',
          prevEl: '.similar-listings__slider .btn-prev',
        },
        breakpoints: {
          600: {
            slidesPerView: 2,
          },
          993: {
            slidesPerView: 3,
          },
        }
      })
    }
  }
})