global.modules.set({
  is_booted: false,
  async init() {
    if(this.is_booted) {
      return;
    }
    this.is_booted = true;
    var preloader = $('.preloader');
    global.preloader = preloader.clone().show();
    preloader.remove();
    global.displayLoadingIndicator = (context) => {
      if (context == null) {
        return;
      }
      context = $(context || document.body);
      const preloader = global.preloader.clone();
      if (context[0] && context[0].tagName == 'BODY') {
        preloader.css('margin-top', `${window.scrollY}px`)
        $('body').addClass('lock');
      }
      context.addClass('loading').append(preloader);
    }
    global.hideLoadingIndicator = (context) => {
      if (context == null) {
        return;
      }
      $('body').removeClass('lock');
      $(context || document.body).removeClass('loading').find('.preloader').remove();
    }
  }
})