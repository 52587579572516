$(window).on('maps_loaded', function () {
  initSearch();
  global.modules.set({
    async init() {
      initSearch();
    }
  })
});

function initSearch() {
  var input = document.getElementById('pac-input');
  var form = $(input).closest('form');
  form.on('submit', function () {
    if (!input.value) {
      document.getElementById('logtitude-input').value = '';
      document.getElementById('latitude-input').value = '';
      document.getElementById('place-type-input').value = '';
    }
  })
  var type_select = $('[name="type"]', form);
  var type = type_select.val();
  if ($('#map.creating').length) {
    return;
  }
  type == 'location' || !type ? initAutocomplete() : initSearchAutocomplete();
  $('[name="type"]').on('change', function () {
    const type = $(this).val();
    $('#pac-input').val('');
    if (type == 'location') {
      $('#pac-input').off('input focusout focusin blur keypress');
      initAutocomplete();
    } else {
      google.maps.event.clearInstanceListeners(input);
      google.maps.event.clearInstanceListeners(window.autocomplete);
      initSearchAutocomplete(type_select.find(":selected").attr('url'));
    }
  })
}

function initAutocomplete() {
  const elem = document.getElementById('pac-input');
  if (!elem) {
    return;
  }
  const autocomplete = new google.maps.places.Autocomplete(elem, {
    fields: ['place_id', 'geometry', 'formatted_address', 'name', 'types', 'address_components'],
  });
  autocomplete.setTypes([
    // 'locality',
    // 'sublocality',
    // 'administrative_area_level_1',
    // // 'street_address',
    // 'sublocality_level_4',
    // 'subpremise',
    // 'street_number',
    // 'street_address',
    // 'room',
    // 'neighborhood',
    // 'route',
    // 'country',
    // 'regions',
    // 'address'
  ])
  global.autocomplete = autocomplete;
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (!place.geometry || !place.geometry.location) {
      return;
    }
    if ($(elem).attr('multiple')) {
      const rows = $('.selected');
      const maxItems = $(elem).attr('max_items');
      const rowsCount = rows.children().length;
      elem.value = '';
      if (maxItems && maxItems <= rowsCount) {
        global.showNotificationPopup(`You can select only ${maxItems} location(s)`);
        return;
      }
      var longtitude = place.geometry.location.lng();
      var latitude = place.geometry.location.lat();
      var item = $(`
        <li>
          <button type="button" class="filter-field">
            <input type="hidden" name="location[]" value="${place.formatted_address}">
            <input type="hidden" name="latitude[]" value="${latitude}">
            <input type="hidden" name="longitude[]" value="${longtitude}">
            ${place.formatted_address}
          </button> 
        </li>
      `);
      $('.selected').append(item);
      item.on('click', function (e) {
        if ($('.filter-field').length == 1) {
          $('.filter-field:last').find('[name]:first').trigger('delete_last_element', [null]);
          $(this).remove();
        } else {
          $(this).remove();
          $('.filter-field:last').find('[name]:first').trigger('change');
        }
        e.stopPropagation();
      })
      item.find('[name]:first').trigger('change');
      return;
    }
    document.getElementById('logtitude-input').value = place.geometry.location.lng();
    document.getElementById('latitude-input').value = place.geometry.location.lat();
    document.getElementById('place-type-input').value = place.types[0];
    if (place.types[0] === 'administrative_area_level_1' && place.address_components[0].long_name === 'New York') {
      document.getElementById('pac-input').value = `${place.address_components[0].long_name} State USA`;
    } else {
      place.address_components.forEach((component) => {
        const component_types = JSON.stringify(component.types);
        switch (component_types) {
          case JSON.stringify(['street_number']):
            $('input[name="street_number"]').val(component.long_name);
            break;
          case JSON.stringify(['route']):
            $('input[name="route"]').val(component.long_name);
            break;
          case JSON.stringify(['locality', 'political']):
            $('input[name="locality"]').val(component.long_name);
            break;
          case JSON.stringify(['administrative_area_level_1', 'political']):
            $('input[name="state_short"]').val(component.short_name);
            break;
          case JSON.stringify(['country', 'political']):
            const country_short_name = component.short_name === 'US' ? 'USA' : component.short_name;
            $('input[name="country"]').val(country_short_name);
            break;
          default:
            break;
        }
      })
    }
    $(elem).closest('form').trigger('submit');
  });
}

function initSearchAutocomplete(url) {
  var timer = null;
  $('#pac-input').on('input focusin', function (e) {
    var elem = $(this);
    var data = new FormData(elem.closest('form')[0]);
    clearTimeout(timer);
    timer = setTimeout(function () { handleSearch(data, url) }, 300);
  });
  $('#pac-input').on('keypress', function (e) {
    var elem = $(this);
    if (e.key == "Enter") {
      e.preventDefault();
      var data = new FormData(elem.closest('form')[0]);
      clearTimeout(timer);
      timer = setTimeout(function () { handleSearch(data, url) }, 300);
    }
  });
  $('.search-btn').on('click', function () {
    var elem = $('#pac-input');
    var data = new FormData(elem.closest('form')[0]);
    clearTimeout(timer);
    timer = setTimeout(function () { makeRequest(data, url) }, 300);
  })
  $('#pac-input').on('focusout blur', handleFocusOut);
}

function handleFocusOut() {
  setTimeout(function () {
    $('.search-list').hide();
  }, 200)
}

function handleSearch(data, url) {
  if (data.get('q').length >= 3) {
    makeRequest(data, url);
  } else {
    $('.search-list').empty().hide();
  }
}

function makeRequest(data, url) {
  $('.search-list').show()
  $.ajax(url || '/search', {
    method: 'POST',
    data,
    elem: $('.search-list'),
    success: function (data) {
      setTimeout(function () {
        $('.search-list').empty().append(data);
      }, 300)
    }
  })
}