global.modules.set({
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;
    let footerLoadMore = document.querySelector('.lists__more');

    if (footerLoadMore) {
      footerLoadMore.addEventListener('click', () => {
        let footerLists = [...document.querySelectorAll('.list--hidden')];
        let elems = footerLists.slice(0, 8);
        elems.forEach((item, index) => {
          if (index == 0) {
            item.classList.add('mt-5');
          }
          item.hidden = true;
          item.classList.remove('list--hidden');
          $(item).slideDown()
        })
        if (footerLists.length < 9) {
          footerLoadMore.style.display = 'none';
        }
      })
    }
  }
})