function successHandler(data) {
  var form = $('[action="' + this.url + '"]');
  if (!data.success) {
    removeErrors(form);
    showNotificationPopup(data.message, 'error');
  } else if (data.success && data.message) {
    showNotificationPopup(data.message, 'success');
    if (data.redirect) {
      window.location = data.redirect;
    }
  } else if (data && data.redirect) {
    window.location = data.redirect
  }
  if (form.closest('.popup')[0]) {
    form.closest('.popup').trigger('hide').removeClass('active');
  }
  if ($('body').hasClass('lock')) {
    this.lockbody = false;
  }
  if (form.attr('reset')) {
    $(form).each(function () {
      this.reset()
    });
  }
}

global.successHandler = successHandler;

function errorHandler(response) {
  if (response.statusText == 'abort') {
    return;
  }
  var data = response.responseJSON;
  var form = $('[action="' + this.url + '"]');
  removeErrors(form);
  if (data && data.errors) {
    if (data.errors.length) {
      showNotificationPopup('Please check all fields.', 'error')
    }
    data.errors.forEach((error) => {
      removeError('[name="' + error.field + '"]')
      showError('[name="' + error.field + '"]', error.message);
    })
    if (!form.find('.error__label').length) {
      showNotificationPopup(data.errors[0].message, 'error');
    }
  } else {
    showNotificationPopup(data && data.message, 'error');
  }
}

global.errorHandler = errorHandler;

$.ajaxSetup({
  success: successHandler,
  error: errorHandler,
  delay: 200,
  elem: $(document.body),
  beforeSend: function () {
    if ($('body').hasClass('lock')) {
      this.lockbody = true;
    }
    var csrf = $('[name="csrf"]').attr('value');
    this.headers = { ...(this.headers || {}), 'x-csrf-token': csrf }
    if (this.method !== 'GET') {
      if (this.data) {
        if (this.data instanceof FormData) {
          this.data.set('_csrf', csrf)
        } else if (typeof this.data == 'string') {
          var data = JSON.parse(this.data);
          data._csrf = csrf;
          this.data = JSON.stringify(data);
        }
      } else {
        this.data = new FormData();
        this.data.set('_csrf', csrf)
      }
    }
    global.displayLoadingIndicator(this.elem);
  },
  complete: function () {
    global.hideLoadingIndicator(this.elem);
    if (this.lockbody) {
      $('body').addClass('lock');
    }
  },
  processData: false,
  contentType: false,
  enctype: 'multipart/form-data',
})


$(document.body).on('submit', 'form.ajax', function (e) {
  e.preventDefault();
  global.defaultSubmitHandler(this, { elem: this });
});