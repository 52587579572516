function makeRequest({ page } = {}) {
  $.ajax({
    url: '/notifications',
    method: 'POST',
    elem: $('.notify-list'),
    data: JSON.stringify({ page }),
    contentType: "application/json; charset=utf-8",
    success: function (data) {
      if (data.success && data.view) {
        if (!page) {
          $('.notify-list').empty()
        }
        $('.notify-list')
          .append(data.view);
        $('.notify-list').get().forEach(function (el) {
          el.meta = data.meta;
        })
      }
      $('.notify-block button').removeClass('new');
      $('.notify-list').on('scroll', function () {
        var elem = $(this)
        if (elem[0].scrollHeight - elem[0].scrollTop === elem[0].clientHeight && elem[0].meta.current_page < elem[0].meta.last_page) {
          makeRequest({ page: elem[0].meta.current_page + 1 });
        }
      });
    }
  })
}

$('.notify-block button').on('click', function () {
  if(!$('.notify-list.active').length) {
    $('.notify-list').scrollTop(0);
    makeRequest();
  }
});

$('.notify-list').on('hide', function () {
  $(this).empty();
  $('.notify-list').off('scroll');
});


global.modules.set({
  async init(context) {
    if(global.user_id) {
      setTimeout(function () {
        $.ajax({
          method: 'POST',
          url: '/notifications/check',
          elem: null,
          success: function (data) {
            if (data) {
              if (data.has_notifications) {
                $('.notify-block button').addClass('new');
              } else {
                $('.notify-block button').removeClass('new');
              }
            }
          }
        })
      }, 200)
    }
  }
})


