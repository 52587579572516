//accordion
jQuery.fn.extend({
  accordion: function (callback) {
    return $(this).children().each(function () {
      $(this).accordion_elem(callback)
      $(this).find('>:nth-child(1)').on('click', function () {
        $(this).parent().siblings().find('>:nth-child(2)').slideUp(500);
      })
    });
  },
  accordion_elem: function (callback) {
    var head = $(this).find('>:nth-child(1)');
    $(this).find('>:nth-child(2)').hide();
    if (typeof callback == 'function') {
      callback(head);
    }
    head.on('click', function () {
      var elem = $(this);
      elem.next().slideToggle(500);
    })
  }
});
