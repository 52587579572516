global.modules.set({
  async init(context) {
    $('.forma__section', context).find('textarea').each(function() {
      var elem = $(this);
      var maxLength = elem.attr('maxlength') || 1000;
      if(elem.val().length >= maxLength) {
        elem.val(elem.val().slice(0, maxLength))
      }
      elem.after(`<span class="fs-18">${elem.val().length}/${maxLength}</span>`);
      elem.on('input change reset paste', function() {
        if(elem.val().length >= maxLength) {
          elem.val(elem.val().slice(0, maxLength))
        }
        elem.next().text(`${elem.val().length}/${maxLength}`)
      })
      elem.closest('form').on('reset', function() {
        setTimeout(function() {
          elem.next().text(`${elem.val().length}/${maxLength}`)
        }, 0)
      })
    })
  }
})