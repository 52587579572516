function showError(el, message) {
  $(el).closest('.forma__section').addClass('error').append(`
    <p class="error__label error-invalid"><span>${message}</span></p>
  `)
}
global.showError = showError;

function removeError(el) {
  $(el).closest('.forma__section').removeClass('error').find('.error__label').remove();
}
global.removeError = removeError;

function removeErrors(context) {
  $(context).find('.forma__section').removeClass('error');
  $(context).find('.error__label').remove();
}
global.removeErrors = removeErrors;

$.validator.setDefaults({
  ignore: [],
});

$('form').on('reset', function () {
  global.removeErrors($(this));
})

function showNotificationPopup(message, type = 'error') {
  const types_color = {
    error: '#E46666',
    success: '#43C367'
  }
  $('.message-alert.active').remove();
  var clone = $('.message-alert').clone();
  clone.css('background', types_color[type]);
  clone.addClass('active').find('p').append(message || 'some error occured');
  clone.appendTo('body');
  setTimeout(function () {
    clone.remove();
  }, 5000)
  clone.on('click', function (e) {
    e.stopPropagation();
  })
  clone.find('.alert-cross').on('click', function (e) {
    e.stopPropagation();
    clone.remove();
  })
}

$('.message-alert.active').each(function () {
  elem = $(this);
  elem.find('.alert-cross').on('click', function () {
    elem.remove();
  });
  setTimeout(function () {
    elem.remove();
  }, 5000);
});

global.showNotificationPopup = showNotificationPopup;

global.defaultSubmitHandler = function (form, { elem } = { elem: undefined }) {
  (async () => {
    var thisForm = $(form);
    const formData = new FormData(thisForm[0]);
    if (thisForm.find('.dropzone').length) {
      var dropzone = thisForm.find('.dropzone');
      var name = dropzone.attr('name');
      dropzone.get()[0].dropzone.files.map(function (file, idx) {
        if (typeof file == 'string') {
          formData.append(`${name.split('[]')[0]}[${idx}].reference`, file);
        } else {
          formData.append(`${name.split('[]')[0]}[${idx}].file`, file);
        }
      })
    }

    if (thisForm.find('.cropper').length) {
      var previews = thisForm.find('.preview');
      await Promise.all(previews.map(async function (i) {
        if (previews[i].cropper && previews[i].cropper.cropped) {
          const blob = await new Promise(resolve => previews[i].cropper.getCroppedCanvas().toBlob(resolve));
          formData.append($(previews[i]).attr('cropped_name'), blob, 'test.png');
          // formData.set(
          //   $(previews[i]).attr('cropper_data_name'),
          //   JSON.stringify(previews[i].cropper.getData())
          // );
        }
      }))
    }
    var res = thisForm.find('.mask').get().map(function (el) {
      const elem = $(el);
      if (elem.attr('disabled')) {
        return;
      }
      return { name: elem.attr('name'), value: elem.val() };
    }).filter(el => el)
      .reduce((acc, cur) => {
        if (cur.name && cur.value) {
          if (acc[cur.name]) {
            if (Array.isArray(acc[cur.name])) {
              acc[cur.name] = [...acc[cur.name], cur.value];
            } else {
              acc[cur.name] = [acc[cur.name], cur.value]
            }
          } else {
            acc[cur.name] = cur.value;
          }
        }
        return acc;
      }, {})
    Object.entries(res).map(([key, value]) => {
      formData.delete(key);
      if (Array.isArray(value)) {
        value.map(el => formData.append(key, el));
      } else {
        formData.set(key, value);
      }
    })
    if (grecaptcha && thisForm.attr('grecaptcha')) {
      const token = await grecaptcha.execute(global.CAPTCHA_PUBLIC_KEY, { action: thisForm.attr('id') });
      formData.set('reCAPTCHA', token)
    }
    $.ajax({
      url: thisForm.attr('action'),
      data: formData,
      method: thisForm.attr('method'),
      // traditional: true,
      elem
    });
  })()
}

function validateForm({ selector, rules, submitHandler }) {
  $(selector).validate({
    ignore: ":hidden",
    focusInvalid: false,
    invalidHandler: function (e, validator) {
      if (!validator.numberOfInvalids()) return;
    },
    keyup: true,
    submitHandler: submitHandler || global.defaultSubmitHandler,
    rules,
    showErrors: function (errorMap, errorList) {
      removeError(this.lastActive);
      errorList.forEach(error => {
        removeError(error.element);
        showError(error.element, error.message);
      })
    },
  })
}
global.validateForm = validateForm;