global.modules.set({
  is_booted: false,
  async init() {
    if(this.is_booted) {
      return;
    }
    this.is_booted = true;
    let buttonPlay = document.querySelector('.video__play')
    let videoBlock = document.querySelector('.about-platform__video')
    let video = document.querySelector('.video__block');
    if (buttonPlay) {
      buttonPlay.addEventListener('click', () => {
        videoBlock.classList.toggle('active');
        buttonPlay.classList.toggle('active');
        video.play();
        video.controls = true
      })
      // video.onpause = function () {
      //   videoBlock.classList.toggle('active');
      //   buttonPlay.classList.toggle('active');
      //   video.controls = false;
      //   video.pause()
      // }
    }
  }
})