validateForm({
  selector: '#feedback_request',
  rules: {
    email: {
      required: true,
      email: true
    },
    name: {
      required: true
    }
  },
  // submitHandler: function(form) {
  //   var thisForm = $(form);
  //   $.ajax({
  //     url: thisForm.attr('action'),
  //     data: new FormData(thisForm[0]),
  //     method: thisForm.attr('method'),
  //     success: function(data) {
  //       if (data && data.success) {
  //         showNotificationPopup(data.message, 'success');
  //       } else {
  //         showNotificationPopup(data.message, 'error');
  //       }
  //     }
  //   });
  // }
});