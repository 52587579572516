global.modules.set({
  async init(context) {
    $(window, context).off('click').on('click', function (e) {
      if (!$(e.target).hasClass('popup_body') && !$(e.target).closest('.popup_body').length) {
        $('.popup').each(function () {
          var popup = $(this);
          if (popup.hasClass('active') && !popup.hasClass('inner')) {
            $('body').removeClass('lock');
            $('.popup').trigger('hide')
              .removeClass('active');
            $('.popup').closest('.menu-parameters, .share-profile').removeClass('active');
            $('.popup_body').find('form').trigger('reset');
          }
        })
      }
    })
    $('.popup_body', context).find('button[type="reset"]').on('click', function () {
      $('.popup').trigger('hide').removeClass('active');
      $('body').removeClass('lock');
    })
  
    $('.popupbtn', context).on('click', function (e) {
      e.stopPropagation();
      var popupId = $(this).attr('popup_id');
      var popup = $('.popup[popup_id="' + popupId + '"]')
      popup.removeAttr('hidden').addClass('active');
      if ($(this).hasClass('lockbody') && popup.length) {
        $('body').addClass('lock');
      }
      $(this).closest('.menu-parameters, .share-profile').toggleClass('active');
      $('.popup[popup_id!="' + popupId + '"]').attr('hidden', '').removeClass('active');
      $('.popup[popup_id!="' + popupId + '"]').closest('.menu-parameters, .share-profile').removeClass('active');
      $('.select__values')
      .not($(e.target).closest('.select__body').find('.select__values'))
      .slideUp(300)
      .closest('.select__body')
      .removeClass('active');
    })
  }
})