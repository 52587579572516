function handleChange(e, item) {
  item = item || e.target;
  if (item.tagName == 'A') {
    return;
  }

  let selectList = item.closest('.select__values');
  if (!selectList) return;
  let body = selectList.closest('.select__body');
  let field = body.querySelector('.select__field')
  $(selectList).slideUp(300);
  body.classList.remove('active');
  body.querySelector('.select__field').textContent = item.textContent;
  selectList.querySelector('.select__options').childNodes.forEach(item => {
    item.classList.remove('active')
  })
  item.classList.add('active')
  field.classList.remove('select__field--placeholder');
  $(body).next().find('option').removeAttr('selected');
  $(body).next().find('option:contains("' + item.textContent + '")').each(function () {
    if ($(this).text().trim() == item.textContent) {
      $(this).attr('selected', 'selected').trigger('keyup');
    }
  })
  $(body).next().trigger('change');
}

global.initSelect = body => {
  body.querySelector('.select__field').addEventListener('click', (e) => {
    $(e.target.parentElement).toggleClass('active');
    $(e.target.nextElementSibling).slideToggle(300);
  })
  $(body).find('.select__option').on('click', handleChange)
  // var item = body.nextElementSibling;
  // var form = $(item).attr('form') ? $(`#${$(item).attr('form')}`) : $(item).closest('form');
  // form.on('reset', function (e) {
  // });
}

global.createSelect = function (item) {
  item.classList.add('created');
  let selectBody = document.createElement('div')
  selectBody.classList.add('select__body')

  let selectField = document.createElement('button')
  selectField.classList.add('select__field')
  selectField.type = 'button';

  let selectValues = document.createElement('div');
  selectValues.classList.add('select__values');
  selectValues.hidden = true;

  let selectOptions = document.createElement('div');
  selectOptions.classList.add('select__options');

  selectBody.append(selectField);
  selectBody.append(selectValues);
  selectValues.append(selectOptions);
  [...item.options].forEach(item => {
    let selectOption = document.createElement('button');
    selectOption.type = 'button';

    selectOption.textContent = item.label;
    selectOption.classList.add('select__option');
    selectOptions.append(selectOption);
    if (item.hasAttribute('selected')) {
      selectField.textContent = item.label;
      selectOption.classList.add('active');
      if (item.classList.contains('placeholder')) {
        selectField.classList.add('select__field--placeholder')
      } else {
        selectField.classList.remove('select__field--placeholder')
      }
    }
    if (item.classList.contains('placeholder')) {
      if (!item.hasAttribute('selectable')) {
        selectOption.hidden = true;
        selectOption.disabled = true;
      }
      selectField.textContent = item.label;
      selectOption.classList.add('select__placeholder');
      selectField.classList.add('select__field--placeholder')
    }
  });
  return selectBody
}

window.addEventListener('click', (e) => {
  $('.select__values')
    .not($(e.target).closest('.select__body').find('.select__values'))
    .slideUp(300)
    .closest('.select__body')
    .removeClass('active');
})

global.modules.set({
  async init(context) {
    $(context || document).find('.select:not(.created)').each(function () {
      var item = $(this)[0];
      item.before(global.createSelect(item));
    })
    $(context || document).find('.select__body').each(function () {
      var item = $(this)[0];
      global.initSelect(item);
    })
  }
})