global.modules.set({
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    if (document.getElementById('map') || document.getElementById('pac-input')) {
      this.is_booted = true;

      var script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${global.GOOGLE_API_KEY}&libraries=places&language=en&callback=initMap`;
      script.async = true;
      window.initMap = function () {
        $(window).trigger('maps_loaded', google);
      };
      document.body.appendChild(script);
    }
  }
})
