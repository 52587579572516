global.modules.set({
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;

    socket = window.io(`wss://${location.host}`, {
      addTrailingSlash: false
    });

    socket.on("connection", () => {
      console.log('socket sucessfully connected')
    });

    socket.on("connect_error", (err) => {
      console.error(err);
    });

    socket.on('new:message', (data) => {
      var messenger = $('.messanger');
      if (messenger[0]) {
        messenger.trigger('new:message', data)
      }
    })

    socket.on('read:chat', (data) => {
      var messenger = $('.messanger');
      if (messenger[0]) {
        messenger.trigger('read:chat', data)
      }
    })

    socket.on('new:chat', (data) => {
      var messenger = $('.messanger');
      if (messenger[0]) {
        messenger.trigger('new:chat', data)
      }
    })

    socket.on('notification', (data) => {
      if (data.type == 'message' && $('.messanger')[0]) {
        $.ajax({
          method: 'POST',
          url: '/notifications/read/' + data.id,
          complete: null,
          success: null,
          error: null,
          elem: null
        })
      } else {
        $('.notify-block button').addClass('new');
      }
    })

  }
})