
global.onload = function() {
  grecaptcha.ready(function () {
    $(document).on('submit', 'form', function(e) {
      form = $(this);
      grecaptcha.execute(global.CAPTCHA_PUBLIC_KEY, { action: 'submit' }).then(function (token) {
        let captchaInput = form.find('[name="reCAPTCHA"]');
        if(!captchaInput.length) {
          captchaInput = $(`<input type="hidden" name="reCAPTCHA" value="${token}">`)
          form.append(captchaInput);
        } else {
          captchaInput.val(token);
        }
      });
    })
  });
}