(function () {
  const lazyBackgrounds = [].slice.call($('.lazy-background'));
  if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
    let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
})()

for(let image of document.getElementsByTagName("img")) {
  image.addEventListener("error", function(event) {
    event.target.src = "/images/default_listing_image.png"
    event.onerror = null
  })
}
