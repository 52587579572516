global.modules = {
  init: async function(context) {
    await Promise.all(
      this.modules.map(function(el) {
        return el.init(context);
      })
    )
  },
  modules: [],
  set: function(module) {
    this.modules = [ ...this.modules, module];
  }
}
